<template>
	<div class="VipPay">
		<div v-if="curType == 'pay'">
			<div class="nav mt24">
				<div class="flex justify-between">
					<div class="left fs16 fw-7">
						您选择的{{ detail.level_name }}会员，{{ detail.remark }}。
					</div>
					<div>
						<div class="right fs14 font-33 flex">
							应付金额<span>{{ detail.price }}</span
							>元
						</div>
						<div class="right fs14 font-33 flex mt12">
							积分抵扣<span>
								{{
									Number(detail.price) * Number(userInfo.score_change_rate)
								}}</span
							>积分
						</div>
					</div>
				</div>
				<div class="fs14 font-33 mt16">
					订单提交成功，请尽快付款！订单号: {{ order_sn }}
				</div>
			</div>

			<div class="tit fs18 fw-7 font-33 mt32">
				<div class="tit-line flex">
					<span class="pr">选择支付方式</span>
					<span class="line">选择支付方式</span>
				</div>
			</div>

			<div class="change flex justify-between mt24 flex-wrap">
				<div
					v-for="item in arrs"
					:key="item.name"
					@click="change(item)"
					:class="{ active: item.name == cur.name }"
					class="item pr00 cursor fs16 font-33 br12 flex align-center justify-center"
				>
					<img :src="item.icon" />
					{{ item.txt
					}}<span v-if="item.name == '余额'" class="fs14 font-99 ml8"
						>余额：{{ userInfo.user_money }}</span
					>
					<span v-if="item.name == '积分'" class="fs14 font-99 ml8"
						>积分{{ userInfo.score }}</span
					>
				</div>
			</div>

			<div class="pay-code flex justify-center">
				<div
					v-loading="loading"
					v-if="cur.name == '微信' || cur.name == '支付宝'"
					class="code pr00"
				>
					<div class="pr00">
						<img v-if="cur.name == '支付宝'" :src="aliObj" />
						<img v-if="cur.name == '微信'" :src="wxObj.url" />
						<div class="flex align-center justify-center fs14 font-33 mt24">
							<img src="@/assets/imgs/vip-pay-code-icon.png" />打开手机{{
								cur.name
							}}
						</div>
					</div>
				</div>

				<div v-else class="btn">
					<el-button :loading="payLoading" @click="sumber(cur.name)"
						>立即支付</el-button
					>
				</div>
			</div>
		</div>

		<div class="success flex flex-direction align-center text-c" v-else>
			<img src="@/assets/icon/success.png" />
			<div class="fs16 fw-7 font-33 mt32">支付成功</div>
			<div class="fs16 font-66 mt16" v-if="cur.name == '余额'">
				支付金额：￥{{ detail.price }}
			</div>
			<div class="fs16 font-66 mt16" v-else>
				支付积分：{{
					Number(detail.price) * Number(userInfo.score_change_rate)
				}}
			</div>
			<div class="fs16 font-66 mt16">支付方式：{{ cur.name }}</div>
			<div class="footitle hande" @click="jump">返回首页</div>
		</div>
	</div>
</template>
<script>
import { mapState } from "vuex";
export default {
	name: "VipPay",
	computed: {
		...mapState({
			userInfo: (state) => state.userInfo,
		}),
	},
	props: {
		// 会员信息
		detail: {
			required: true,
		},
	},
	data() {
		return {
			curType: "pay", // 当前是什么 pay 支付; success 支付完成;
			order_sn: "", // 订单号
			loading: true, // 是请求二维码中
			payLoading: false, // 是否余额支付中
			// 当前是那个支付
			cur: {
				icon: require("@/assets/public/wallet-ali.png"),
				txt: "支付宝支付",
				name: "支付宝",
				type: "alipay",
			},
			// 支付的列表
			arrs: [
				{
					icon: require("@/assets/public/wallet-ali.png"),
					txt: "支付宝支付",
					name: "支付宝",
					type: "alipay",
				},
				{
					icon: require("@/assets/public/wallet-wx.png"),
					txt: "微信支付",
					name: "微信",
					type: "wxpay",
				},
				{
					icon: require("@/assets/public/balance.png"),
					txt: "余额支付",
					name: "余额",
					type: "",
				},
				{
					icon: require("@/assets/imgs/about-personal-integral.png"),
					txt: "积分支付",
					name: "积分",
					type: "",
				},
			],

			aliObj: "", // 阿里的支付数据
			wxObj: {}, // 微信的支付数据
			payTimer: null, // 支付的轮询
			payClearTimer: false, // 是否清除定时器轮询
		};
	},
	async created() {
		console.log("this.cur", "需要生成订单", this.detail);
		this.loading = true;
		this.payClearTimer = false;

		await this.$API
			.postAddOrder({
				order_type: 16,
				order_money: this.detail.price,
				payable_money: this.detail.price,
				level_id: this.detail.id,
				pay_type: this.cur.type,
			})
			.then((res) => {
				if (res.code == 1) {
					this.order_sn = res.data.order_sn;
					this.payCallback();
					this.AlipayCode();
				} else {
					this.$message.error(res.msg);
				}
			})
			.catch((err) => {
				console.log("err", err);
			});
		this.$pageScrollTop();
	},
	destroyed() {
		this.payClearTimer = true;
		clearTimeout(this.payTimer);
	},
	methods: {
		//跳转首页
		jump() {
			this.$router.push({
				name: "Index",
				params: {},
			});
		},
		// 支付回调
		payCallback() {
			clearTimeout(this.payTimer);
			if (this.order_sn && !this.payClearTimer) {
				this.payTimer = setTimeout(() => {
					clearTimeout(this.payTimer);
					this.$API
						.payCallback({
							order_sn: this.order_sn,
						})
						.then((res) => {
							if (res.code == 1) {
								if (res.data.is_pay == 1) {
									this.order_sn = "";
									this.curType = "success";
									this.$emit("operate", {});

									clearTimeout(this.payTimer);
								} else {
									!this.payClearTimer && this.payCallback();
								}
							} else {
								console.log(res.msg);
								this.payClearTimer = true;
							}
						})
						.catch((err) => {
							console.log("err", err);
							this.payClearTimer = true;
						});
				}, 2000);
			}
		},
		// 生成微信二维码
		generateWxCode() {
			this.loading = true;
			let { order_sn } = this;
			this.$API
				.generateWxCode({
					order_sn,
					wxpaytype: "native",
				})
				.then((res) => {
					this.loading = false;
					if (res.code == 1) {
						this.wxObj = res.data;
					} else {
						this.$message.error(res.msg);
					}
				})
				.catch((err) => {
					console.log("err", err);
				});
		},
		// 生成支付宝二维码
		AlipayCode() {
			this.loading = true;
			let { order_sn } = this;
			this.$API
				.AlipayCode({
					order_sn,
					alipay_method: "native",
				})
				.then((res) => {
					this.loading = false;
					if (res.code == 1) {
						this.aliObj = res.data;
						console.log(res, this.aliObj);
					} else {
						this.$message.error(res.msg);
					}
				})
				.catch((err) => {
					console.log("err", err);
				});
		},
		// 支付切换
		change(item) {
			let { aliObj, wxObj } = this;
			this.cur = item;
			this.money = item.add_money;
			if (
				(item.type == "alipay" && !aliObj) ||
				(item.type == "wxpay" && !wxObj.url)
			) {
				this.generateWxCode();
				this.AlipayCode();
			}
		},
		///
		sumber(e) {
			if (e == "积分") {
				this.btns();
			}
			if (e == "余额") {
				this.btn();
			}
		},

		//积分支付
		btns() {
			if (
				Number(this.detail.price) * Number(this.userInfo.score_change_rate) >
				Number(this.userInfo.score)
			) {
				this.$message.info("积分不足");
				return false;
			}

			if (this.payLoading) {
				return false;
			}
			this.payLoading = true;

			this.$API
				.getOperationjifenplay({
					order_sn: this.order_sn,
				})
				.then((res) => {
					this.payLoading = false;
					if (res.code == 1) {
						this.payClearTimer = true;
						clearTimeout(this.payTimer);
						this.curType = "success";
						this.$emit("operate", {});
					} else {
						this.$message.error(res.msg);
					}
				})
				.catch((err) => {
					this.payLoading = false;
					console.log("err", err);
				});
		},

		// 余额支付
		btn() {
			if (this.userInfo.user_money < this.detail.price) {
				this.$message.info("余额不足");
				return false;
			}

			if (this.payLoading) {
				return false;
			}
			this.payLoading = true;

			this.$API
				.balancePay({
					order_sn: this.order_sn,
				})
				.then((res) => {
					this.payLoading = false;
					if (res.code == 1) {
						this.payClearTimer = true;
						clearTimeout(this.payTimer);
						this.curType = "success";
						this.$emit("operate", {});
					} else {
						this.$message.error(res.msg);
					}
				})
				.catch((err) => {
					this.payLoading = false;
					console.log("err", err);
				});
		},
	},
};
</script>
<style scoped lang="scss">
.VipPay {
	@extend .w100;
	@extend .h100;

	.nav {
		background-color: #fff7ef;
		padding: 32px;

		.left {
			color: #ff8300;
		}

		.right {
			align-items: baseline;

			span {
				font-size: 20px;
				color: #f03c3b;
			}
		}
	}

	.change {
		div {
			margin-bottom: 16px;
		}
		.item {
			width: 270px;
			height: 80px;
			border: 1px solid #eeeeee;

			&:last-child {
				margin-right: 0 !important;
			}

			&:after {
				content: "";
				display: none;
				width: 32px;
				height: 32px;
				background: url("../assets/imgs/vip-pay-change-icon.png");
				background-size: 100% 100%;
				content: " ";
				position: absolute;
				bottom: 0;
				right: 0;
			}

			&.active {
				border-color: #ff8300;
				position: relative;

				&:after {
					display: block;
				}
			}

			img {
				margin-right: 16px;
				width: 32px;
				height: 32px;
			}
		}
	}

	.pay-code {
		padding-top: 40px;

		.code {
			background-color: #ffffff;
			box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);

			> div {
				background-color: #ffffff;
				z-index: 2;
				padding: 20px;

				> img {
					width: 180px;
					height: 180px;
				}

				div {
					img {
						width: 14px;
						height: 14px;
						margin-right: 4px;
					}
				}
			}

			&:before {
				content: "";
				z-index: 1;
				display: block;
				width: 20px;
				height: 20px;
				background-color: #ffffff;
				position: absolute;
				left: 50%;
				top: -10px;
				box-shadow: inherit;
				transform: translateX(-50%) rotateZ(45deg);
			}
		}

		.btn {
			margin-top: 32px;

			button {
				border: none;
				background-color: #ff8300;
				width: 240px;
				height: 48px;
				border-radius: 24px;
				font-size: 16px;
				color: #ffffff;
			}
		}
	}

	.success {
		img {
			height: 80px;
			width: auto;
			margin-top: 100px;
		}

		div {
			max-width: 360px;
		}
	}
}
.footitle {
	width: 240px;
	height: 48px;
	background: #f6f7f9;
	font-size: 16;
	color: #333;
	line-height: 48px;
	text-align: center;
	opacity: 1;
	border-radius: 24px;
	margin-top: 94px;
}
.hande:hover {
	cursor: pointer;
}
</style>
