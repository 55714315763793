<template>
	<div v-loading="initLoading" class="Vip bgfff br4 p32">
		<!-- 我的会员 start -->

		<div v-if="curType == 'myVip'" class="my-vip">
			<div
				class="tit fs18 fw-7 font-33 pr00 pb24 flex justify-between align-center"
			>
				<div class="tit-line flex">
					<span class="pr">我的会员</span>
					<span class="line">我的会员</span>
				</div>
				<span @click="curType = 'history'" class="right fw-4 fs14"
					>购买记录</span
				>
			</div>
			<!-- 个人信息 start -->
			<div class="top flex pr00 br4 mt24">
				<div class="left pr00">
					<img :src="userInfo.head_img" alt="头像" class="head" />
					<img
						v-if="userInfo.user_level > 0"
						class="vip"
						src="@/assets/my/vip.png"
					/>
				</div>
				<div class="right flex flex-direction justify-center">
					<div class="fs18 fw-7 font-33">
						{{ userInfo.user_nickname }}({{ userInfo.mobile }})
					</div>
					<div v-if="userInfo.user_level > 0" class="mt16 font-33">
						您已成为会员，享超值权益，有效期至{{ userInfo.vip_last_time }}
					</div>
					<div v-else class="mt16 font-33">
						开通会员成为终身会员，享超值权益
					</div>
				</div>
			</div>
			<!-- 个人信息 end -->

			<!-- 选择种类 start -->
			<div class="change flex flex-wrap mt24">
				<div
					v-for="item in arrs"
					:key="item.id"
					@click="cur = item"
					:class="{ active: item.id == cur.id }"
					class="item mb16 pr00 cursor"
				>
					<div class="text-c fs16 fw-7">{{ item.level_name }}</div>
					<div class="price flex justify-center mt16 fs22 fw-7">
						<span>￥</span>
						{{ item.price }}
					</div>
					<div
						class="mr8 zhe flex justify-center ml"
						v-if="item.old_money != 0"
					>
						（原价{{ item.old_money }}元）
					</div>
					<div class="text-c mt16 fs16">{{ item.remark }}</div>
				</div>
			</div>
			<!-- 选择种类 end -->

			<!-- 权益 start -->
			<div class="benefit">
				<div class="benefit-tit flex align-center justify-center">
					<img class="left" src="@/assets/imgs/vip-left-icon.png" />
					<span class="mlr16 fs16 fw-7">会员权益</span>
					<img class="right" src="@/assets/imgs/vip-right-icon.png" />
				</div>

				<div class="con-wrap pr00">
					<div class="con-bg pa00">
						<div class="t-1 horn position-absolute flex"><span></span></div>
						<div class="t-2 horn position-absolute flex"><span></span></div>
						<div class="t-3 horn position-absolute flex"><span></span></div>
						<div class="t-4 horn position-absolute flex"><span></span></div>
					</div>

					<div class="con">
						<div v-for="item in benefitArrs" :key="item.id" class="pr00">
							{{ item.name }}
						</div>
					</div>
				</div>
			</div>
			<!-- 权益 end -->

			<div class="btn flex align-center justify-center">
				<div @click="curType = 'pay'">立即购买</div>
			</div>
		</div>
		<!-- 我的会员 end -->

		<!--  购买记录 start -->
		<div v-if="curType == 'history'" class="history">
			<div class="tit flex justify-between align-center">
				<div class="left">
					<!-- 面包屑 start -->
					<div class="bread flex align-center font-33 pb24">
						您现在的位置：<el-breadcrumb
							separator="/"
							separator-class="el-icon-arrow-right"
						>
							<el-breadcrumb-item
								@click.stop.prevent.native="curType = 'myVip'"
								class="is-link"
								>我的会员</el-breadcrumb-item
							>
							<el-breadcrumb-item>购买记录</el-breadcrumb-item>
						</el-breadcrumb>
					</div>
					<!-- 面包屑 end -->
				</div>
				<span @click="jump('Invoice')" class="right">开发票</span>
			</div>
			<VipHistory :curType.sync="curType"></VipHistory>
		</div>
		<!--  购买记录 end -->

		<!-- 收银台 start -->
		<div v-if="curType == 'pay'">
			<!-- 面包屑 start -->
			<div
				class="bread flex align-center font-33 pb24"
				style="border-bottom: 1px solid #eeeeee"
			>
				您现在的位置：<el-breadcrumb
					separator="/"
					separator-class="el-icon-arrow-right"
				>
					<el-breadcrumb-item
						@click.stop.prevent.native="curType = 'myVip'"
						class="is-link"
						>我的会员</el-breadcrumb-item
					>
					<el-breadcrumb-item>支付</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
			<!-- 面包屑 end -->

			<VipPay :detail="cur" @operate="operate"></VipPay>
		</div>
		<!-- 收银台 end -->
	</div>
</template>
<script>
import { mapState } from "vuex";
import VipHistory from "@/components/VipHistory.vue";
import VipPay from "@/components/VipPay.vue";
export default {
	name: "Vip",
	components: {
		VipHistory,
		VipPay,
	},
	computed: {
		...mapState({
			userInfo: (state) => state.userInfo,
		}),
	},
	data() {
		return {
			curType: "myVip", // 当前在那里  myVip 我的会员; history 购买记录; pay 支付;
			initLoading: true, // 初始化
			loading: false, // 接口请求中
			cur: {}, // 当前的会员类别
			arrs: [], // 会员充值类别
			benefitArrs: [], // 权益
		};
	},
	created() {
		this.getList();
		this.getBenefit();
	},
	methods: {
		// 支付完成
		operate() {
			this.$API
				.getUserInfo({
					user_id: this.userInfo.id,
				})
				.then((ress) => {
					if (ress.code == 1) {
						let obj = { ...this.userInfo, ...ress.data };
						this.$store.commit("login", obj);
					}
				})
				.catch((err) => {
					console.log("err", err);
				});
		},
		// 获取权益
		getBenefit() {
			this.$API
				.memberBenefit()
				.then((res) => {
					if (res.code == 1) {
						this.benefitArrs = res.data;
					} else {
						this.$message.info(res.msg);
					}
				})
				.catch((err) => {
					console.log("err", err);
				});
		},
		// 获取列表
		getList() {
			this.$API
				.memberList()
				.then((res) => {
					this.initLoading = false;
					if (res.code == 1) {
						this.arrs = res.data;
						this.cur = this.arrs[0];
					} else {
						this.$message.info(res.msg);
					}
				})
				.catch((err) => {
					console.log("err", err);
				});
		},

		jump(name) {
			this.$router.push({
				name,
			});
		},
	},
};
</script>
<style scoped lang="scss">
::v-deep {
	.bread {
		.is-link {
			span {
				font-weight: 700;
				text-decoration: none;
				transition: color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
				color: #303133;
				cursor: pointer;

				&:hover {
					color: #409eff;
				}
			}
		}
	}
}

.Vip {
	@extend .w100;
	@extend .h100;

	.my-vip {
		.tit {
			border-bottom: 1px dashed #eeeeee;

			.right {
				cursor: pointer;
				color: #4177dd;
			}
		}

		.top {
			width: 700px;
			overflow: hidden;
			background: url(../../assets/imgs/about-personal-bg.png) no-repeat 100%
				100%;
			padding: 24px 0 28px 32px;

			.left {
				width: 72px;
				height: 72px;

				.head {
					width: 100%;
					height: 100%;
					border-radius: 50%;
				}

				.vip {
					width: 18px;
					height: 26px;
					position: absolute;
					right: 0;
					bottom: 0;
				}
			}

			.right {
				padding-left: 12px;
				flex: 1;
			}
		}

		.change {
			width: 720px;
			min-height: 360px;

			.item {
				width: 342px;
				background: #fff6ea;
				border-radius: 14px;
				border: 2px solid transparent;
				color: #95661f;
				padding-top: 32px;
				padding-bottom: 40px;

				&:nth-child(2n + 2) {
					margin-left: 16px;
				}

				.price {
					align-items: baseline;

					span {
						font-size: 14px;
						font-weight: 500;
					}
				}

				&:after {
					content: "";
					display: none;
					width: 32px;
					height: 32px;
					background: url("../../assets/imgs/vip-change.png");
					background-size: 100% 100%;
					content: " ";
					position: absolute;
					bottom: 0;
					right: 0;
				}

				&.active {
					background-color: #fff6ea;
					border-color: #966720;

					&:after {
						display: block;
					}
				}
			}
		}

		.benefit {
			min-height: 200px;
			width: 700px;
			padding-top: 16px;

			.benefit-tit {
				img {
					width: 126px;
					height: 7px;
				}

				span {
					color: #95661f;
				}
			}

			.con-wrap {
				margin-top: 24px;
				overflow: hidden;

				.con-bg {
					background-color: #ffffff;
					border: 1px solid #917141;
					width: 100%;
					height: 100%;

					.horn {
						width: 24px;
						height: 24px;
						background-color: #ffffff;
						z-index: 2;

						span {
							position: absolute;
							width: 48px;
							height: 12px;
							top: 1px;
							left: -12px;
							background-color: inherit;
						}

						&:before {
							content: "";
							display: block;
							position: absolute;
							left: -8px;
							top: 0;
							height: 1px;
							width: 40px;
							background-color: #917141;
						}

						&:after {
							content: "";
							display: block;
							width: 8px;
							height: 8px;
							border-radius: 50%;
							background-color: #917141;
							position: absolute;
							left: 50%;
							top: 50%;
							transform: translate(-50%, -50%);
						}

						&.t-1 {
							left: -8px;
							top: -8px;
							transform: rotateZ(135deg);
						}

						&.t-2 {
							right: -8px;
							top: -8px;
							transform: rotateZ(-135deg);
						}

						&.t-3 {
							left: -8px;
							bottom: -8px;
							transform: rotateZ(45deg);
						}

						&.t-4 {
							right: -8px;
							bottom: -8px;
							transform: rotateZ(-45deg);
						}
					}
				}

				.con {
					padding: 22px 0;
					padding-right: 24px;

					div {
						padding: 10px 0;
						padding-left: 80px;

						&:after {
							content: "";
							display: block;
							width: 8px;
							height: 8px;
							border-radius: 50%;
							background-color: #917141;
							position: absolute;
							left: 56px;
							top: 16px;
						}
					}
				}
			}
		}

		.btn {
			width: 700px;
			padding-top: 40px;
			padding-bottom: 12px;

			div {
				border-radius: 200px;
				padding: 12px 88px;
				cursor: pointer;
				background: linear-gradient(to right, #f5d991, #e5b96d);
			}
		}
	}

	.history {
		.tit {
			border-bottom: 1px dashed #eeeeee;

			.right {
				cursor: pointer;
				color: #4177dd;
			}
		}
	}
}
.zhe {
	font-size: 14px !important;
	//text-decoration: line-through;
	margin-left: 16px;
	margin-top: 2px;
}
</style>
