<template>
	<div class="VipHistory bgfff br4">
		<div style="min-height: 600px" v-loading="listLoading">
			<div v-if="arrs.length > 0 || listLoading">
				<div
					v-for="(item, index) in arrs"
					:key="index"
					class="list-item mlr32 bgfff pt24 pb32"
				>
					<div class="tit flex justify-between fs14 font-33 fw-7 mt12">
						<div style="flex: 1" class="fs16">{{ item.level_name }}</div>
						<span class="">￥{{ item.level_price }}</span>
					</div>
					<div class="fs14 font-66 mt12">订单编号：{{ item.order_sn }}</div>
					<div class="fs14 font-66 mt12">支付方式：{{ item.pay_type }}</div>
					<div class="fs14 font-66 mt12">购买时间：{{ item.pay_time }}</div>
				</div>
			</div>
			<div
				v-else
				class="mt24 bgfff br4"
				style="overflow: hidden; padding-bottom: 60px"
			>
				<Empty></Empty>
			</div>
		</div>

		<!-- 分页 start -->
		<div class="page-wrap flex justify-end mt32">
			<el-pagination
				:background="true"
				layout="prev, pager, next"
				:total="total"
				:current-page="page"
				:page-size="pageSize"
				@current-change="change"
				hide-on-single-page
			>
			</el-pagination>
		</div>
		<!-- 分页 end -->
	</div>
</template>
<script>
export default {
	name: "VipHistory",
	props: {
		curType: {},
	},
	watch: {
		curType: {
			handler(newVal) {
				if (newVal && newVal == "history") {
					this.change(1);
				}
			},
			immediate: true,
		},
	},
	data() {
		return {
			arrs: [],
			total: 0, // 总条数
			pageSize: 15, // 每页条数
			page: 1, // 当前的页码
			listLoading: false,
		};
	},
	created() {
		this.getList();
	},
	methods: {
		// 分页改变时
		change(e) {
			this.page = e;
			this.getList();
		},

		// 数据列表
		getList() {
			if (this.listLoading) {
				return false;
			}

			let { page, pageSize } = this;

			this.listLoading = true;
			this.$pageScrollTop();

			this.$API
				.vipOrderList({
					page,
					pagesize: pageSize,
					list_rows: pageSize,
					pageSize,
				})
				.then((res) => {
					this.listLoading = false;

					if (res.code == 1) {
						this.total = res.data.total;
						this.arrs = res.data.data;
					} else {
						this.$message.error(res.msg);
					}
				})
				.catch((err) => {
					console.log("err", err);
				});
		},

		/**
		 * ! 是否显示弹窗
		 * @param {sign} sign 显示隐藏 true 显示 false 隐藏
		 * @param {ref} ref 那个类型
		 */
		popupFun(sign, ref) {
			if (sign) {
				try {
					this.$refs[ref].open();
				} catch (error) {
					this.$refs[ref].show();
				}
			} else {
				try {
					this.$refs[ref].close();
				} catch (error) {
					this.$refs[ref].hide();
				}
			}
		},
	},
};
</script>
<style scoped lang="scss">
.VipHistory {
	@extend .w100;
	@extend .h100;

	.nav {
		border-bottom: 1px dashed #eeeeee;
	}

	.list-item {
		border-bottom: 1px solid #eeeeee;

		.tit {
			align-items: flex-start;

			span {
				font-size: 16px;
				font-weight: 500;
				color: #f03c3b;
			}
		}

		.right {
			height: 100%;
			font-size: 32px;
			font-family: Lato;
			font-weight: 500;
			color: #f03c3b;
		}
	}
}
</style>
